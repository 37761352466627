//IMPORTANT: this page must be called "signup-success" in the URL, because the app checks for that URL to determine if something has been signed up.
//this is being resolved here: 

import { ConfirmationPage } from "@components/web/ConfirmationPage";
import HeaderDefaultWithLogo from "@components/web/HeaderDefaultWithLogo";
import { useLocalization } from "@hooks/localization";
import { NoSsr } from "@material-ui/core";

export default () => {
    const t = useLocalization();
    return <>
        <HeaderDefaultWithLogo />
        <NoSsr>
            <ConfirmationPage
                showButton={false}
                titleH2={t("Plus_Signup_Confirmation_SuccesMessageTitle")}
                text={<span dangerouslySetInnerHTML={{ __html: t("Plus_Signup_Confirmation_SuccesMessage") }} />} />
        </NoSsr>
    </>
}